import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";

import parse from 'html-react-parser';

const AboutUsPage = () => {
  const data  = useStaticQuery(graphql`
    query AboutPages {
      allWpPage(filter: {uri: {eq: "/about-us/"}}) {
        nodes {
          title,
          aboutUsPage {
            aboutUsDescription
            aboutUsImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, placeholder: BLURRED, webpOptions: {quality: 50})
                }
              }
            }
            founderImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, placeholder: BLURRED, webpOptions: {quality: 50})
                }
              }
            }
            founderDescription
          }
        }
      }
    }
  `);
  
  let pageTitle = data.allWpPage.nodes[0].title;
  let aboutUsPage = data.allWpPage.nodes[0].aboutUsPage;
  let aboutUsImage = aboutUsPage.aboutUsImage.localFile.childImageSharp.gatsbyImageData;
  let founderImage = aboutUsPage.founderImage.localFile.childImageSharp.gatsbyImageData;
  let founderDescription = aboutUsPage.founderDescription;
  
  return (
    <Layout>
      <Seo title="About Us"/>
      <section className="container about-us-page page-default">
        <div className="text-center text-lg-start">
          <h1 className="display-2 pt-5">{  pageTitle }</h1>
        </div>
        <div className="row mt-5">
          <div className="col-12 col-lg-5">
            <div className="about-us-description">
              <p className="fs-5">{ parse(aboutUsPage.aboutUsDescription) }</p>
              <p className="fs-5">Find out more about our services <Link to="/services">here</Link>.</p>
            </div>
          </div>
          <div className="col-12 col-lg-7 col-xl-5">
            <div className="about-us-image">
              <GatsbyImage image={ aboutUsImage } alt="About Us Image"/>
            </div>
          </div>
        </div>
        <div className="row founder-section">
          <div className="col-12 col-lg-4 d-flex flex-column">
            <h3 className="display-5 text-center">Meet the Founder</h3>
            <div className="mx-auto">
              <GatsbyImage className="founder-image" image={ founderImage } alt="Steve Fanega Image"/>   
            </div>
          </div>
          <div className="col-12 col-lg-5 d-flex flex-column my-auto">
            <p className="fs-3 fw-bold text-center text-lg-start mb-0">Steve Fanega II</p>
            <p className="fs-6 fst-italic text-center text-lg-start mb-0">Owner &amp; Founder</p>
            <p className="fs-5 text-center text-lg-start">{ parse(founderDescription) }</p>
          </div>
        </div>
        </section>
    </Layout>
  );
}

export default AboutUsPage;